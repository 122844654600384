import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import {
  FluentThemeProvider,
  DEFAULT_COMPONENT_ICONS,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
  createStatefulCallClient,
  StatefulCallClient,
} from "@azure/communication-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CallingComponents from "./CallingComponentsStateful";
import { initializeIcons, registerIcons } from "@fluentui/react";
import { Call, CallAgent } from "@azure/communication-calling";
import axios, { AxiosRequestConfig } from "axios";

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

function App(): JSX.Element {
  const { groupId, name } = useParams();

  const [statefulCallClient, setStatefulCallClient] =
    useState<StatefulCallClient>();
  const [callAgent, setCallAgent] = useState<CallAgent>();
  const [call, setCall] = useState<Call>();
  const [getToken, setGetToken] = useState<boolean>(false);
  const [userAccessToken, setUserAccessToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);

  const config: AxiosRequestConfig = {
    url: process.env.REACT_APP_API_SIGNTURE,
    method: "GET",
  };

  const getSignature = async () => {
    try {
      const res = await axios(config);
      if (res.data) {
        setUserAccessToken(res.data.token);
        setUserId(res.data.user.communicationUserId);
        setGetToken(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSignature();
  }, []);

  useEffect(() => {
    if (getToken && userId) {
      setStatefulCallClient(
        createStatefulCallClient({
          userId: { communicationUserId: userId },
        })
      );
    }
  }, [getToken]);

  useEffect(() => {
    if (getToken && userAccessToken) {
      const tokenCredential = new AzureCommunicationTokenCredential(
        userAccessToken
      );
      if (callAgent === undefined && statefulCallClient && name) {
        const createUserAgent = async () => {
          setCallAgent(
            await statefulCallClient.createCallAgent(tokenCredential, {
              displayName: name,
            })
          );
        };
        createUserAgent();
      }
    }
  }, [statefulCallClient, name, callAgent]);

  useEffect(() => {
    if (callAgent !== undefined && groupId) {
      setCall(callAgent.join({ groupId }));
    }
  }, [callAgent]);

  return (
    <>
      <FluentThemeProvider>
        {!statefulCallClient && <h4>Carregando...</h4>}
        {statefulCallClient && (
          <CallClientProvider callClient={statefulCallClient}>
            {callAgent && (
              <CallAgentProvider callAgent={callAgent}>
                {call && (
                  <CallProvider call={call}>
                    <CallingComponents />
                  </CallProvider>
                )}
              </CallAgentProvider>
            )}
          </CallClientProvider>
        )}
      </FluentThemeProvider>
    </>
  );
}

export default App;
