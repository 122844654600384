import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import {
  FluentThemeProvider,
  DEFAULT_COMPONENT_ICONS,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
  createStatefulCallClient,
  StatefulCallClient,
} from "@azure/communication-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CallingComponents from "./CallingComponentsStateful";
import { initializeIcons, registerIcons } from "@fluentui/react";
import { Call, CallAgent } from "@azure/communication-calling";
import axios, { AxiosRequestConfig } from "axios";
import { ReactComponent as Logo } from "./assets/bria_logo.svg";

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });

function PatientView(): JSX.Element {
  const { groupId, name, time } = useParams();

  const now = new Date().getTime();

  const [statefulCallClient, setStatefulCallClient] =
    useState<StatefulCallClient>();
  const [callAgent, setCallAgent] = useState<CallAgent>();
  const [call, setCall] = useState<Call>();
  const [getToken, setGetToken] = useState<boolean>(false);
  const [userAccessToken, setUserAccessToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [isTime, setIsTime] = useState<boolean>(false);

  const config: AxiosRequestConfig = {
    url: process.env.REACT_APP_API_SIGNTURE,
    method: "GET",
  };

  const viewSizes = {
    width: "70vw",
    height: "70vh",
  };
  const checkIsTime = () => {
    if (time && parseInt(time) - now <= 15 * 60) {
      setIsTime(true);
    }
  };

  const getSignature = async () => {
    try {
      const res = await axios(config);
      if (res.data) {
        setUserAccessToken(res.data.token);
        setUserId(res.data.user.communicationUserId);
        setGetToken(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    checkIsTime();
    getSignature();
  }, []);

  useEffect(() => {
    if (getToken && userId) {
      setStatefulCallClient(
        createStatefulCallClient({
          userId: { communicationUserId: userId },
        })
      );
    }
  }, [getToken]);

  useEffect(() => {
    if (getToken && userAccessToken) {
      const tokenCredential = new AzureCommunicationTokenCredential(
        userAccessToken
      );
      if (callAgent === undefined && statefulCallClient && name) {
        const createUserAgent = async () => {
          setCallAgent(
            await statefulCallClient.createCallAgent(tokenCredential, {
              displayName: name,
            })
          );
        };
        createUserAgent();
      }
    }
  }, [statefulCallClient, name, callAgent]);

  useEffect(() => {
    if (callAgent !== undefined && groupId) {
      setCall(callAgent.join({ groupId }));
    }
  }, [callAgent]);

  return (
    <>
      <FluentThemeProvider>
        <div style={{ width: viewSizes.width, margin: "16px auto" }}>
          <Logo width="150px" color="#2F054D" />
        </div>
        {!isTime && (
          <div style={{ width: viewSizes.width, margin: "16px auto" }}>
            <h4>
              O acesso à consulta será liberado 15 minutos antes do horário
              marcado. Retorne próximo ao horário da consulta.
            </h4>
          </div>
        )}
        {!statefulCallClient && isTime && (
          <div style={{ width: viewSizes.width, margin: "16px auto" }}>
            <h4>Carregando...</h4>
          </div>
        )}

        {statefulCallClient && isTime && (
          <CallClientProvider callClient={statefulCallClient}>
            {callAgent && (
              <CallAgentProvider callAgent={callAgent}>
                {call && (
                  <CallProvider call={call}>
                    <CallingComponents {...viewSizes} />
                  </CallProvider>
                )}
              </CallAgentProvider>
            )}
          </CallClientProvider>
        )}
      </FluentThemeProvider>
    </>
  );
}

export default PatientView;
