import {
  usePropsFor,
  VideoGallery,
  ControlBar,
  CameraButton,
  MicrophoneButton,
  useCall,
} from "@azure/communication-react";
import { mergeStyles, Stack } from "@fluentui/react";
import { useEffect } from "react";

interface ICallingComponents {
  width?: string;
  height?: string;
}

function CallingComponents(props: ICallingComponents): JSX.Element {
  const videoGalleryProps = usePropsFor(VideoGallery);
  const cameraProps = usePropsFor(CameraButton);
  const microphoneProps = usePropsFor(MicrophoneButton);

  const getDevices = async () => {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
  };
  useEffect(() => {
    getDevices();
  }, []);

  const call = useCall();

  if (call?.state === "Disconnected") {
    return <CallEnded />;
  }

  return (
    <Stack className={mergeStyles({ height: "100%" })}>
      <div
        style={{
          width: props.width ?? "100vw",
          height: props.height ?? "100vh",
          margin: "auto",
        }}
      >
        {videoGalleryProps && (
          <VideoGallery layout="floatingLocalVideo" {...videoGalleryProps} />
        )}
      </div>

      <ControlBar layout="floatingBottom">
        {cameraProps && <CameraButton {...cameraProps} />}
        {microphoneProps && <MicrophoneButton {...microphoneProps} />}
      </ControlBar>
    </Stack>
  );
}

function CallEnded(): JSX.Element {
  return <h1>You ended the call.</h1>;
}

export default CallingComponents;
